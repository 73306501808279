















import Vue from 'vue'
import passwordUpdate from '@/components/PasswordUpdateForm.vue'

export default Vue.extend({
  components: {
    passwordUpdate,
  },
})
