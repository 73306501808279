





























































import Vue from 'vue'
import { ApiResponse } from '@/interfaces'

export default Vue.extend({
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      showSuccess: false,
      showNotification: false,
      notificationType: 'success',
      notificationMessage: '',
      busy: false,
      hasLowerChar: false,
      hasUpperChar: false,
      hasNumber: false,
      hasSpecialChar: false,
      isMinLength: false,
      confirmationOk: false,
      passwordRegexLow: /^(?=.*?[a-z]).+$/,
      passwordRegexUp: /^(?=.*?[A-Z]).+$/,
      passwordRegexNumber: /^(?=.*?[0-9]).+$/,
      passwordRegexSpecial: /^(?=.*?[#?!@$%^&*-]).+$/,
    }
  },
  computed: {
    isValid(): boolean {
      return this.hasLowerChar && this.hasUpperChar && this.hasNumber && this.hasSpecialChar && this.isMinLength
    },
  },
  watch: {
    password(newVal) {
      this.isMinLength = newVal.length >= 8
      this.hasLowerChar = this.passwordRegexLow.test(newVal)
      this.hasUpperChar = this.passwordRegexUp.test(newVal)
      this.hasNumber = this.passwordRegexNumber.test(newVal)
      this.hasSpecialChar = this.passwordRegexSpecial.test(newVal)
    },
    passwordConfirmation(newVal) {
      this.confirmationOk = newVal === this.password
    },
  },
  methods: {
    submit() {
      this.showNotification = false
      this.busy = true

      this.$api
        .post('/password_update', {
          token: this.$route.params.token,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          // Failsafe: removing currently stored JWT
          this.$cookies.remove('authToken')

          if (apiResponse.success) {
            this.showSuccess = true
          }
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse
            this.showNotification = true
            this.notificationType = 'error'
            this.notificationMessage =
              apiResponse.message ??
              'Une erreur est survenue lors de la modification de votre mot de passe. Le lien que vous avez utilisé est peut-être invalide ou expiré. Merci de reéssayer.'
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    resetForm() {
      this.hasLowerChar = false
      this.hasUpperChar = false
      this.hasNumber = false
      this.hasSpecialChar = false
      this.isMinLength = false
      this.confirmationOk = false
      this.showNotification = false
    },
  },
})
